import React, { type ComponentProps, type ComponentType, useContext } from 'react';

import { getAssetUrlsFromId } from '@atlassian/react-loosely-lazy-manifest';

import { getConfig, MODE } from '../../config';
import { PHASE } from '../../constants';
import { LazySuspenseContext } from '../../suspense';
import { getExport } from '../../utils';
import { createLoaderError } from '../errors';
import { type ServerLoader } from '../loader';

function load<C>(moduleId: string, loader: ServerLoader<C>) {
	try {
		return getExport(loader());
	} catch (err) {
		throw createLoaderError(err);
	}
}

export function createComponentServer<C extends ComponentType<any>>({
	dataLazyId,
	defer,
	loader,
	moduleId,
	ssr,
}: {
	dataLazyId: string;
	defer: number;
	loader: ServerLoader<C>;
	moduleId: string;
	ssr: boolean;
}) {
	return (props: ComponentProps<C>) => {
		const Resolved = ssr ? load(moduleId, loader) : null;
		const { fallback } = useContext(LazySuspenseContext);
		const { crossOrigin, manifest, onChunkRender, mode, react18 } = getConfig();

		onChunkRender(moduleId, defer);

		if (mode === MODE.HYDRATE && react18) {
			if (!Resolved) {
				const error = new Error('lazy-fallback');
				// TODO: find a way to mark this error as "ok"
				// error.ignore = true;
				throw error;
			}
			return <Resolved {...props} />;
		}
		return (
			<>
				<input type="hidden" data-lazy-begin={dataLazyId} />
				{defer !== PHASE.LAZY &&
					getAssetUrlsFromId(manifest, moduleId)?.map((url: any) => (
						<link
							key={url}
							rel={defer === PHASE.PAINT ? 'preload' : 'prefetch'}
							href={url}
							crossOrigin={crossOrigin}
							as="script"
						/>
					))}
				{Resolved ? <Resolved {...props} /> : fallback}
				<input type="hidden" data-lazy-end={dataLazyId} />
			</>
		);
	};
}
